import React from 'react'
import DeshboardBanner from './SubComponent/DeshboardBanner';
import DeshboardContent from './SubComponent/DeshboardContent';
import DeshJoin from './SubComponent/DeshJoin';
import Footer from './SubComponent/Footer';
import Navbar from './SubComponent/Navbar';
import {Helmet} from "react-helmet";
import BannerPart from './SubComponent/BannerPart';
import BannerLinebar from './SubComponent/BannerLinebar';
import {useSelector} from "react-redux";
function Deshboard() {

    const auth = useSelector(state => state.auth);


    return (
        <>
            <Helmet>
                <title>AgencyReel | Dashboard </title>
            </Helmet>
          <div className="wrapper-box">
           <Navbar/> 
           <BannerLinebar/>
           <DeshboardBanner/>

              {
                  auth.user.client == "0" ?
                      <>
                          <DeshboardContent/>
                          <BannerPart/>
                          <DeshJoin/>
                      </>
                  : ''
              }


           </div>
        </>
    )
}

export default Deshboard
